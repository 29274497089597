<template>

    <div class="mb-4">
        <!-- 関連動画 -->
        <div class="flex justify-between items-center mb-4">
            <div class="block items-center mb-2">
                <label class="block mr-2 font-semibold text-base">関連動画リンクの確認</label>
            </div>
            <button @click="openModal" class="text-blue-500">関連動画リンクとは</button>
            <!-- 関連動画リンク説明モーダル -->
            <div v-if="isInfoModalOpen"
                class="modal fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
                @click="closeModal">
                <div class="modal-content bg-white p-5 px-2 mx-5 rounded-lg max-w-md related-video-link-modal" v-scroll-lock="isInfoModalOpen" @click.stop>
                    <h3 class="text-xl font-bold mb-4">関連動画リンクとは？</h3>
                    <p class="mb-2 text-body-2 balanced-text">
                        記事本文の下に表示される関連するYouTube動画のリンクです。最大3本まで表示することが可能で、初期設定では、あなたの連携しているYouTubeチャンネルの視聴回数が多い3本の動画が自動で選ばれます（情報の更新にはタイムラグがあります）。<br>
                        動画を手動で変更したい場合は「https://www.youtube.com/watch?v=id」形式のURLを入力してください。
                    </p>
                    <p class="mb-4 text-body-2">※表示サンプル</p>

                    <div class="rounded-lg flex justify-center items-center">
                        <img src="../assets/relatedvideo.svg" class="items-center bg-gray-200 p-2 rounded-lg" alt="関連動画リンクの説明画像">
                    </div>

                    <button @click="closeModal"
                        class="mt-4 bg-gray-500 text-white p-2 rounded">閉じる</button>
                </div>
            </div>
        </div>
        <div v-for="index in 3" :key="index" class="mb-2 relative block items-center rounded">
            <!-- Youtube動画枠 -->
            <div class="lg:col-span-1 bg-gray-100 h-auto w-75">
                <iframe v-if="isURL(embedUrls[index - 1])" :src="embedUrls[index - 1]" title="YouTube video player" class="w-full aspect-video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
                <iframe v-else :srcdoc="embedUrls[index - 1]" title="YouTube video player" class="w-full aspect-video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </div>
            <!-- URL入力ボックス -->
            <input type="text" v-model="inputUrls[index - 1]" @blur="() => validateURL(index)"
                class="mt-3 p-2 w-full rounded flex-grow bg-white border" placeholder="関連動画に設定したい動画のURLを入力してください">
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, watch, onMounted, defineProps} from 'vue';
//===========================
// 再生数TOP3の動画を表示
//===========================
const props = defineProps({
    relatedVideoUrls: Array
});
//親コンポーネントの変数を監視
watch(() => props.relatedVideoUrls, (newVal) => {
    if (newVal.length > 0) {
        for (let i = 0; i < inputUrls.value.length; i++) {
            inputUrls.value[i] = newVal[i] || '';
        }
        for (let i = 0; i <= inputUrls.value.length; i++) {
            validateURL(i);
        }
    }
}, { deep: true });

//===========================
// 関連動画リンクとはのモーダル
//===========================
const isInfoModalOpen = ref(false);
const openModal = () => {
    isInfoModalOpen.value = true;
    document.documentElement.style.setProperty('overflow-y', 'hidden', 'important');
}
const closeModal = () => {
    isInfoModalOpen.value = false;
    document.documentElement.style.removeProperty('overflow-y');
}

//=============================
// 関連動画URLを入力した後の処理
//=============================
var inputUrls = ref(['', '', '']);
const beforeUrls = ref(['', '', '']);
const embedUrls = ref(['', '', '']);
const errors = ref(['', '', '']);
const emit = defineEmits(['parentFunc1','parentFunc2']);

// Youtube動画URLのマッチ・エンベッド
const convertToEmbedUrl = (url) => {
    if (!url) return '';
    const videoIdMatch = url.match(/(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|embed|shorts)\/|\S*?[?&]v=)|youtu.be\/)([a-zA-Z0-9_-]{11})/);
    if (videoIdMatch && videoIdMatch[1]) {
        return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
    }
    return url;
}

// バリデーション
const validateURL = (index) => {
    const inputUrl = inputUrls.value[index - 1];
    if(inputUrl === ''){
        errors.value[index - 1] = '';
        beforeUrls.value[index - 1] = '';
        embedUrls.value[index - 1] =  `
            <html>
                <head><style>body {display: flex;justify-content: center;align-items: center;height: 100vh;margin: 0;font-family: Arial, sans-serif;font-size: 24px;}</style></head>
                <body>
                関連動画が設定されていません
                </body>
            </html>`;
        emit('parentFunc1', beforeUrls.value); //親コンポーネントのファンクション（updateInputUrls）を実施
        return;
    }
    const pattern = new RegExp([
        '^https:\\/\\/www\\.youtube\\.com\\/watch\\?v=[\\w-]{11}(&[\\w-]+=[\\w-]+)*',
        '|https:\\/\\/youtube\\.com\\/watch\\?v=[\\w-]{11}(&[\\w-]+=[\\w-]+)*',
        '|https:\\/\\/youtu\\.be\\/[\\w-]{11}(&[\\w-]+=[\\w-]+)*',
        '|https:\\/\\/m\\.youtube\\.com\\/watch\\?v=[\\w-]{11}(&[\\w-]+=[\\w-]+)*',
        '|https:\\/\\/www\\.youtube\\.com\\/shorts\\/[\\w-]{11}(&[\\w-]+=[\\w-]+)*',
        '|https:\\/\\/youtube\\.com\\/shorts\\/[\\w-]{11}(&[\\w-]+=[\\w-]+)*'
        ].join(''));
    if (pattern.test(inputUrl)) {
        errors.value[index - 1] = '';
        beforeUrls.value[index - 1] = inputUrl;
        embedUrls.value[index - 1] = convertToEmbedUrl(inputUrl);
    } else {
        errors.value[index - 1] = 'NG! Invalid URL format';
        beforeUrls.value[index - 1] = '';
        embedUrls.value[index - 1] = 'https://www.youtube.com/embed/BzwoJI';
    }
    emit('parentFunc1', beforeUrls.value); //親コンポーネントのファンクション（updateInputUrls）を実施
};

const isURL = (str) => {
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
};

watch(() => [...errors.value], (newErrors/*, oldErrors*/) => {
    //console.log(oldErrors,"から",newErrors,"に変更されました。");
    if (newErrors.some((error) => error !== '')) {
        emit('parentFunc2', false); //親コンポーネントのファンクション（updateInputUrlsValidStatus）を実施
    } else {
        emit('parentFunc2', true);  //親コンポーネントのファンクション（updateInputUrlsValidStatus）を実施
    }
}, { deep: true });

//conputed:の処理より後のDOM生成のタイミングで実行
onMounted(() => {
    for (let i = 1; i <= 3; i++) {
        validateURL(i);
    }
});
</script>

<style>
.related-video-link-modal{
    max-width: 446px !important;
    min-width: 350px !important;
}
.balanced-text {
    text-wrap: balance;
    word-break: break-all;
}
</style>