<template>
<div class="flex flex-col items-center justify-between min-h-screen">
    <div class="w-full flex flex-col items-center space-y-4 mt-6">
        <!-- エラーメッセージ -->
        <div v-if="errorMessage" class="flex items-center justify-center px-4 py-3 space-x-3 bg-red-100 border border-red-400 rounded text-red-700" role="alert">
            <img :src="alertIcon" alt="alert icon" class="w-6 h-6">
            <span class="block sm:inline">{{ errorMessage }}</span>
            <span class=" top-0 bottom-0 right-0 px-4 py-3" @click="clearErrorMessage">
                <svg class="w-6 h-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <title>Close</title>
                    <path d="M14.348 14.849a1 1 0 0 1-1.414 0L10 11.414 7.056 14.349a1 1 0 1 1-1.414-1.414L8.586 10 5.642 7.056a1 1 0 0 1 1.414-1.414L10 8.586l2.944-2.944a1 1 0 0 1 1.414 1.414L11.414 10l2.934 2.935a1 1 0 0 1 0 1.414z" />
                </svg>
            </span>
        </div>
        <!-- 新規登録セクション -->
        <div class="p-6 bg-white rounded-lg shadow-md w-full md:w-2/3 lg:w-1/2">
            <h2 class="text-center text-lg font-semibold">新規登録をして利用を開始しましょう</h2>
            <p class="text-center mt-2">YouTubeでログインしているアカウントで登録してください。</p>
            <button @click="handleGoogleAuth('register')" class="mt-4 flex items-center justify-center bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-4 rounded-md border border-gray-300 w-full">
                <img :src="googleIcon" alt="Google icon" class="mr-2 w-4">Googleで登録
            </button>
        </div>

        <!-- ログインセクション -->
        <div class="p-6 bg-white rounded-lg shadow-md w-full md:w-2/3 lg:w-1/2">
            <h2 class="text-center text-lg font-semibold">すでにアカウントをお持ちの方</h2>
            <button @click="handleGoogleAuth('login')" class="mt-4 bg-white hover:bg-gray-100 text-gray-700 font-bold py-3 px-4 rounded-md border border-gray-300 w-full">
                ログインする
            </button>
        </div>

        <div class="p-4 w-full md:w-2/3 lg:w-1/2">
            <span class="text-sm">
            livedoor ECHOESは、Google APIから受信した情報を他のアプリに使用および転送する場合、使用制限要件を含む<a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" class="text-blue-500 hover:text-blue-600">Google APIサービスユーザーデータポリシー</a>を遵守します。
        </span>
        </div>
    </div>
</div>

<ChannelSelectModal v-if="isChannelSelectModalVisible" :channels="channelsDataList" @selected="onChannelSelected" @close="isChannelSelectModalVisible = false" />
</template>

<script>
import {
    ref
} from 'vue';
import {
    useStore
} from 'vuex';
import {
    //googleTokenLogin,
    googleSdkLoaded
} from 'vue3-google-login'; //googleAuthCodeLogin
import axios from 'axios';
import {
    useRouter
} from 'vue-router';
import ChannelSelectModal from './ChannelSelectModal.vue';
import googleIcon from '@/assets/google-color-icon.svg';
import alertIcon from '@/assets/alert.svg';

export default {
    name: 'LoginDialog2',
    components: {
        ChannelSelectModal,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const isChannelSelectModalVisible = ref(false); // モーダル表示制御用
        const channelsDataList = ref([]); // APIから取得したチャンネルリスト
        const errorMessage = ref(''); // エラーメッセージの状態

        // エラーメッセージをクリアする関数
        const clearErrorMessage = () => {
            errorMessage.value = '';
        };

        const sendAuthRequest = async (data) => {
            try {
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                const response = await axios.post(`${apiEndpoint}/user-auth/`, data, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                return response;
            } catch (error) {
                errorMessage.value = error.response.data.error; // エラーメッセージをセット
                console.error("Error in auth request", error);
            }
        };

        const handleGoogleAuth = async (action) => {
            let response;
            try {
                if (action === 'login') {
                    //response = await googleTokenLogin();
                    response = await login();
                } else if (action === 'register') {
                    //response = await googleAuthCodeLogin();
                    response = await register();
                    //console.log(response);
                }

                if (response) {
                    const googleResponse = await sendAuthRequest({
                        access_token: response.access_token || null,
                        code: response.code || null,
                        action: action
                    });
                    console.log(googleResponse);

                    if (googleResponse && googleResponse.status === 200 && action === 'register') {
                        if (googleResponse.data.type === 'multi') {
                            channelsDataList.value = googleResponse.data.channels_data_list; // チャンネルリストをセット
                            isChannelSelectModalVisible.value = true; // モーダル表示
                        } else {
                            proceedToAccountCreation(googleResponse.data.channels_data_list, googleResponse.data.email, googleResponse.data.token);
                        }
                    } else if (googleResponse && googleResponse.status === 200 && action === 'login') {
                        store.dispatch('login', {
                            user_id: googleResponse.data.user_id,
                            accessToken: googleResponse.data.accessToken,
                            expiry: googleResponse.data.expiry
                        });
                        router.push({
                            name: 'VideoManagement'
                        });

                    } else {
                        //console.log(googleResponse);
                    }
                }
            } catch (error) {
                console.error("Error in Google auth", error);
            }
        };

        const register = () => {
            return new Promise((resolve, reject) => {
                googleSdkLoaded((google) => {
                    google.accounts.oauth2.initCodeClient({
                        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                        scope: 'email profile openid https://www.googleapis.com/auth/youtube.readonly', // ここでscopeを指定する
                        callback: (response) => {
                            console.log("Handle the response", response);

                            resolve(response); // Promiseを解決し、responseを返す
                        },
                        errorCallback: (error) => {
                            errorMessage.value = error.response.data.error; // エラーメッセージをセット
                            console.error("Error in register", error); // エラーメッセージをコンソールに出力
                            reject(error); // Promiseを拒否し、エラーを返す
                        }
                    }).requestCode();
                });
            });
        };

        const login = () => {
            return new Promise((resolve, reject) => {
                googleSdkLoaded((google) => {
                    google.accounts.oauth2.initCodeClient({
                        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
                        scope: 'email profile openid https://www.googleapis.com/auth/youtube.readonly', // ここでscopeを指定する
                        callback: (response) => {
                            console.log("Handle the response", response);

                            resolve(response); // Promiseを解決し、responseを返す
                        },
                        errorCallback: (error) => {
                            errorMessage.value = error.response.data.error; // エラーメッセージをセット
                            console.error("Error in login", error); // エラーメッセージをコンソールに出力
                            reject(error); // Promiseを拒否し、エラーを返す
                        }
                    }).requestCode();
                });
            });
        };

        const proceedToAccountCreation = (channelData, email, token) => {
            store.commit('setChannelData', channelData); // VuexのstoreにchannelDataをセット
            store.commit('setemail', email);
            store.commit('settoken', token);
            router.push({
                name: 'AccountCreation',
            });

        };

        const onChannelSelected = (channelData) => {
            isChannelSelectModalVisible.value = false; // モーダルを非表示にする
            proceedToAccountCreation(channelData); // アカウント作成処理を続行
        };

        return {
            isChannelSelectModalVisible,
            channelsDataList,
            handleGoogleAuth,
            onChannelSelected,
            errorMessage,
            clearErrorMessage,
            googleIcon,
            alertIcon

        }
    }
};
</script>
