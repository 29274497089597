<template>
    <div class="dashboard-page">
      <!-- ダッシュボードの内容 -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'DashboardPage',
    // コンポーネントのデータやメソッド
  };
  </script>
  
  <style scoped>

  </style>
  