import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'; // Vuexストアをインポート
import LoginPage from '@/components/LoginPage';
import DashboardPage from '@/components/DashboardPage';
import RegistrationComplete from '@/components/RegistrationComplete';
import VideoManagement from '@/components/VideoManagement';
import ArticleManagement from '@/components/ArticleManagement';
import ChannelManagement from '@/components/ChannelManagement';
import ArticleSettings from '@/components/ArticleSettings';
import AccountCreation from '@/components/AccountCreation';
import AccountCreated from '@/components/AccountCreated';

const routes = [
    {
        path:'/',
        name:'Dashboard',
        component: DashboardPage,
    },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/registration-complete',
    name: 'RegistrationComplete',
    component: RegistrationComplete
  },
  { path: '/video-management',
    name:'VideoManagement',
   component: VideoManagement
   },
  { path: '/article-management',
    name:'ArticleManagement',
  component: ArticleManagement },
  { path: '/channel-management',
  name:'ChannelManagement',
  component: ChannelManagement },
  {
    path: '/articles/settings/:id',
    name: 'ArticleSettings',
    component: ArticleSettings,
    props: true
},
{
  path: '/account-creation',
  name: 'AccountCreation',
  component: AccountCreation,
},
{
  path: '/account-created',
  name: 'AccountCreated',
  component: AccountCreated,
},
{
  path: '/:pathMatch(.*)*', // すべての未定義のパスにマッチする
  name: 'NotFound',
  component: () => import('@/components/NotFound.vue') // 遅延ロード
}

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isAuthenticated) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
