<template>
    <div class="hello">
      <div>
        遷移したよ
      </div>
      <button @click="tokencheck">check</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'SennI1',
    methods: {
        async tokencheck() {
            try {
                const accessToken = this.$store.state.accessToken; // Vuexストアからアクセストークンを取得
                const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
                //'http://127.0.0.1:8000/api/v1/testtoken/'
                const response = await axios.post(`${apiEndpoint}/testtoken/`, 
                    {"c": "たのむ設定できてくれ"},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`  // JWTトークンをヘッダーに含める
                        }
                    }
                );
                console.log(response);
                return response;
            } catch (error) {
                console.error("Error in auth request", error);
            }
        }
    }
}
  </script>
  