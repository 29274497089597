  <template>
    <div class="pt-8 pb-4 px-4 max-w-2xl mx-auto bg-white rounded-lg shadow-md mt-6">
      <h1 class="text-center font-semibold text-xl mb-8">アカウントの登録が完了しました</h1>
      <div class="flex justify-center mb-8">
        <img :src="accountcreatedImg" alt="Registration Complete" class="w-48 h-48">
      </div>
      <p class="text-gray-600 mb-8">
        YouTubeチャンネル情報の登録が完了しました。<br>
        動画管理画面から動画をアップロードして文字起こし記事を作成してください。<br>
        <router-link to="/channel-management" class="text-blue-500 hover:text-blue-700">チャンネル管理ページ</router-link>から登録した情報の確認・編集ができます。
      </p>
      <div class="flex justify-center">
        <router-link to="/video-management" class="text-center w-11/12 py-2 border border-gray-400 text-gray-600 font-semibold rounded-lg hover:bg-gray-100">動画管理ページへ</router-link>
      </div>
    </div>
  </template>

<script>
import { ref } from 'vue';
import accountcreatedImg from '@/assets/accountcreated.png';

export default {
  setup() {
    const accountcreatedImgRef = ref(accountcreatedImg);

    return {
      accountcreatedImg: accountcreatedImgRef,
    };
  },
}
</script>
  